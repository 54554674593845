import React from 'react'

import FeaturesSection from './featuresSection'
import LaunchSection from './launchSection'
import MissionSection from './missionSection'

const HomePage = () =>
  <div className="container">
    <LaunchSection/>
    <FeaturesSection/>
    <MissionSection/>
  </div>

export default HomePage
