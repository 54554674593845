import React from 'react'
import {Outlet} from 'react-router-dom'
import FooterSection from './footerSection'

import styles from './layout.module.scss'

const Layout = () =>
  <div className={styles.all}>
    <div className={styles.main}>
      <Outlet/>
    </div>
    <FooterSection/>
  </div>

export default Layout
