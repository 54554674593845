import styles from './launch.module.scss'

import {Textfit} from 'react-textfit'

const LaunchSection = () =>
  <div className={styles.section} data-testid="launch-section">
    <div className={styles.slogans}>
      <div className={styles.slogan}>
        <Textfit mode={'single'} forceSingleModeWidth={true} min={16} max={100}>
          Live Your Life
        </Textfit>
      </div>
      <div className={styles.slogan}>
        <Textfit mode={'single'} forceSingleModeWidth={true} min={16} max={100}>
          Love Your Legacy
        </Textfit>
      </div>
    </div>
  </div>


export default LaunchSection
