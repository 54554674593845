import React from 'react'

const PrivacyPage = () =>
  <div className="page legal">
    <h1>Privacy</h1>
    <p>While many companies may proclaim to support your privacy and then share everything about you, Veviam is based on
      the fundamental principle of total privacy for our users.</p>
    <p>We have absolutely no desire to know more about you, or squeeze every last cent from you. In fact, not knowing you
      is one of our most valuable assets.</p>
    <p>We only partner with companies that can offer truly meaningful services to all of our users, without
      differentiation and therefore all our users are the same in our eyes.</p>
    <p>Of course, in offering you some of these services, you will be telling us things about yourself and others who are
      important to you. This information will never be used for anything other than the uses you explicitly ask for and at
      the specified time you want the service made available.</p>
    <p>Our technology, and in particular our security model, is built to give you complete control and transparency.</p>
    <p>We fundamentally believe you should own your data so you can own your legacy.</p>
  </div>

export default PrivacyPage
