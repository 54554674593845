import {BrowserRouter, Routes, Route} from 'react-router-dom'

import React from 'react'

import PeopleFeatures from './features/peopleFeatures'
import EchoesFeatures from './features/echoesFeatures'
import HeritageFeatures from './features/heritageFeatures'
import StoriesFeatures from './features/storiesFeatures'
import MediaFeatures from './features/mediaFeatures'
import PartnersFeature from './features/partnersFeature'
import VaultFeatures from './features/vaultFeatures'
import WishesFeatures from './features/wishesFeatures'

import NotFoundPage from './pages/notFoundPage'
import ContactPage from './pages/contactPage'
import FaqPage from './pages/faqPage'
import HomePage from './pages/landing/homePage'
import Layout from './layout/layout'
import PartnersPage from './pages/partnersPage'
import PrivacyPage from './pages/privacyPage'
import TeamPage from './team/teamPage'
import TermsPage from './pages/termsPage'

import BrianHanly from './team/brianHanly'
import GarethPayne from './team/garethPayne'
import MarkBarnes from './team/markBarnes'
import PaulWright from './team/paulWright'
import YamanIslim from './team/yamanIslim'

const App = () =>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<HomePage/>}/>
        <Route path="partners" element={<PartnersPage/>}/>
        <Route path="privacy" element={<PrivacyPage/>}/>
        <Route path="terms" element={<TermsPage/>}/>
        <Route path="faq" element={<FaqPage/>}/>
        <Route path="contact" element={<ContactPage/>}/>

        <Route path="feature">
          <Route path="accounts" element={<PeopleFeatures/>}/>
          <Route path="events" element={<EchoesFeatures/>}/>
          <Route path="heritage" element={<HeritageFeatures/>}/>
          <Route path="stories" element={<StoriesFeatures/>}/>
          <Route path="media" element={<MediaFeatures/>}/>
          <Route path="partners" element={<PartnersFeature/>}/>
          <Route path="vault" element={<VaultFeatures/>}/>
          <Route path="wishes" element={<WishesFeatures/>}/>
        </Route>

        <Route path="team">
          <Route index element={<TeamPage/>}/>

          <Route path="brian.hanly" element={<BrianHanly/>}/>
          <Route path="mark.barnes" element={<MarkBarnes/>}/>
          <Route path="gareth.payne" element={<GarethPayne/>}/>
          <Route path="yaman.islim" element={<YamanIslim/>}/>
          <Route path="paul.wright" element={<PaulWright/>}/>
        </Route>

        <Route path="*" element={<NotFoundPage/>}/>
      </Route>
    </Routes>
  </BrowserRouter>

export default App
