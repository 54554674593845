import {createElement} from 'react'
import {IconContext, IconType} from 'react-icons'
import {BiDna} from 'react-icons/bi'
import {BsJournalText, BsSafe} from 'react-icons/bs'
import {FaHandshake, FaPhotoVideo} from 'react-icons/fa'
import {GiEchoRipples, GiFullMetalBucket} from 'react-icons/gi'
import {IoPeopleOutline} from 'react-icons/io5'
import {Link, To} from 'react-router-dom'
import styles from './features.module.scss'

interface Feature {
  id: string
  icon: IconType
  title: string
  description: string
  link: To
}

const FEATURES: Feature[] = [
  {
    id: 'stories',
    icon: BsJournalText,
    title: 'Stories',
    description: 'A secure digital story book to share those key moments in your life.',
    link: 'feature/stories',
  },
  {
    id: 'media',
    icon: FaPhotoVideo,
    title: 'Media',
    description: 'Curate your photos and videos into beautiful albums capturing your favourite moments.',
    link: 'feature/media',
  },
  {
    id: 'events',
    icon: GiEchoRipples,
    title: 'Echoes',
    description: 'Schedule photos, videos, cards or simple messages to be delivered for key events far into the future.',
    link: 'feature/events',
  },
  {
    id: 'accounts',
    icon: IoPeopleOutline,
    title: 'People',
    description: 'Create multiple accounts for friends and family to access your legacy.',
    link: 'feature/accounts',
  },
  {
    id: 'vault',
    icon: BsSafe,
    title: 'Vault',
    description: 'Ensure all your important information, accounts, social media activity and more is preserved.',
    link: 'feature/vault',
  },
  {
    id: 'heritage',
    icon: BiDna,
    title: 'Heritage',
    description: 'Ensure critical information regarding ancestry and health is available.',
    link: 'feature/heritage',
  },
  {
    id: 'wishes',
    icon: GiFullMetalBucket,
    title: 'Desires',
    description: 'A living will to capture your aspirations for life and beyond.',
    link: 'feature/wishes',
  },
  {
    id: 'partners',
    icon: FaHandshake,
    title: 'Partners',
    description: 'Access to exclusive deals from our wide range of partners.',
    link: 'feature/partners',
  },
]

type IconProps = { icon: IconType };

const FeatureIcon = (props: IconProps) => {
  const icon = createElement(props.icon)
  return <IconContext.Provider value={{size: '2rem'}}>{icon}</IconContext.Provider>
}

const FeatureCard = (feature: Feature) =>
  <Link to={feature.link} className={styles.feature} key={feature.id}>
    <div>
      <div className={styles.icon}><FeatureIcon icon={feature.icon}/></div>
      <div className={styles.title}>
        {feature.title}
      </div>
      <div className={styles.description}>
        {feature.description}
      </div>
    </div>
  </Link>

const FeaturesSection = () =>
  <div className={styles.section} data-testid="features-section">
    <h1>Features</h1>

    <h2>All features are available free to all users, forever.</h2>

    <div className={styles.features}>
      {FEATURES.map(FeatureCard)}
    </div>
  </div>

export default FeaturesSection
