import React from 'react'
import {Link} from 'react-router-dom'
import {Textfit} from 'react-textfit'

import styles from './notFound.module.scss'

const NotFoundPage = () =>
  <div className={styles.missing}>
    <div className={styles.title}>
      <Textfit mode={'single'} forceSingleModeWidth={true} min={16} max={100}>
        <div>Legacy Not Found</div>
      </Textfit>
    </div>
    <div className={styles.subtitle}>
      <Textfit mode={'single'} forceSingleModeWidth={true} min={8} max={100}>
        <Link to="/">
          Don{'\''}t Leave Your Future To Chance
        </Link>
      </Textfit>
    </div>
  </div>

export default NotFoundPage
