import React from 'react'
// import {Link} from 'react-router-dom'

const TeamPage = () =>
  <div className="page">
    <h1>Meet The Team</h1>

    {/*<Link to="/team/mark.barnes">Mark Barnes</Link>*/}
  </div>

export default TeamPage
