import styles from './mission.module.css'

const MissionSection = () =>
  <div className={styles.section} data-testid="mission-section">
    <div className="container feature">
      <h1>Our Mission</h1>
      <p>Veviam was created by a team of socially responsible individuals who wanted to free our future digital legacy
        from the shackles of the ongoing technical innovations that are continually disrupting our lives.</p>
      <p>The digital revolution and in particular social media means that we now capture and share more details about our
        lives than ever before in history. The platforms and tools we use make this easy and practically free. This ease of
        capturing and sharing our data comes at the cost of allowing our lives and attention to be monetised for big
        businesses, and they are BIG businesses. The vast fortunes amassed by the companies that can capitalise on our daily
        lives is staggering.</p>
      <p>Sharing our daily lives with our friends, family and loved ones has become part of our culture, but amongst this
        river of data are real gems. Beautiful images, loving words, fleeting moments that are easily lost in the raging
        torrent. Our lives get caught up in the now and we are losing the time to reminisce. We capture new moments and
        forget about those just past.</p>
      <p>Life is short and when ultimately it is over, our descendants will not be able to find those hidden gems and the
        platforms and tools we used to create them will be silent. Veviam allows us to escape the stream of now
        and find time to reflect. Veviam allows us to escape the now and take some time to think about the future
        in a meaningful way.</p>
      <p>We believe that everyone has the right to be remembered and we are sharing the platform and tools to allow you to
        live your best legacy.</p>
    </div>
  </div>

export default MissionSection
