import {Link} from 'react-router-dom'
import styles from './layout.module.scss'
import logo from '../logo.svg'

const FooterSection = () =>
  <footer className={styles.footer} data-testid='footer'>
    <div className={styles.footerLogo}>
      <Link to="/">
        <img src={logo} alt="veviam logo" title="veviam Logo" width={200} height={72}/>
      </Link>
    </div>
    <div className={styles.footerLinks}>
      <div className={styles.footerLinksColumn}>
        <ul className={styles.footerLinksColumn}>
          {/*<li><Link to="/mission">Mission</Link></li>*/}
          <li><Link to="/team">Team</Link></li>
          {/*<li><Link to="/features">Features</Link></li>*/}
          <li><Link to="/partners">Partners</Link></li>
        </ul>
      </div>
      <div className={styles.footerLinksColumn}>
        <ul>
          <li><Link to="/privacy">Privacy</Link></li>
          <li><Link to="/terms">Terms</Link></li>
          {/*  <li><Link to="/security">Security</Link></li>*/}
          {/*  <li><Link to="/developers">Developers</Link></li>*/}
        </ul>
      </div>
      <div className={styles.footerLinksColumn}>
        <ul>
          {/*<li><Link to="/support">Support</Link></li>*/}
          <li><Link to="/faq">FAQ</Link></li>
          {/*<li><Link to="/community">Community</Link></li>*/}
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
    </div>
    <div className={styles.footerSocialLinks}>
    </div>
  </footer>

export default FooterSection
